import React, { Component } from 'react';
import GenericPage from '../components/genericpage';
import data from '../data/traderpsy.json'


const TraderPsy = () => {
  return (
    <GenericPage PageData = {data} />
  )
}

export default TraderPsy